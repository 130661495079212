import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { CoinsQuery } from "../../../graphql-types";
import Coins from "../../components/pages/coins/coins";
import { NavTab } from "../../components/layout/navigation/navigation";

type CoinsPageProps = PageProps<CoinsQuery>;

const CoinsPage: React.FC<CoinsPageProps> = ({ data }) => {
  return (
    <Layout activeTab={NavTab.Coins}>
      <SEO
        title="Cryptocurrency stats, prices and market insights"
        description="Real-time market insights on cryptocurrency prices, market cap, ranking and other details"
      />

      <Coins staticData={data} />
    </Layout>
  );
};

export default CoinsPage;

export const query = graphql`
  query Coins {
    allCoinListItem(
      limit: 100
      sort: { fields: [market_cap_rank], order: [ASC] }
    ) {
      nodes {
        id
        symbol
        name
        image
        current_price
        market_cap
        market_cap_rank
        price_change_percentage_24h
        price_change_percentage_24h_in_currency
        price_change_percentage_7d_in_currency
      }
    }
  }
`;
